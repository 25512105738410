import { Typography, Box } from "@mui/material";

export default function WelcomeMessage() {
  return (
    <Box>
      <Typography variant="h4" paragraph>
        Welcome from our Director
      </Typography>
      <Typography paragraph>
        As the new Kentucky State Climatologist, let me welcome you to the
        Kentucky Climate Center (KCC).
      </Typography>
      <Typography paragraph>
        Foremost, the Kentucky Climate Center (KCC) is home to the State Climate
        Office and Kentucky Mesonet, located here in Bowling Green, Kentucky, on
        the beautiful campus of Western Kentucky University. Comprised of an
        expert team of faculty, staff, and students, our primary mission is to
        help the state of Kentucky - state and local governments, private
        industry, academia, and the general public - prepare for and mitigate
        against the negative effects from high-impact weather and climate.
      </Typography>
      <Typography paragraph>
        As history demonstrates, Kentuckians are vulnerable to a wide variety of
        severe weather, including floods, droughts, severe winds, snow, ice,
        hail and tornadoes. Subtle shifts and changes in climate, as is caused
        by El Nino and La Nina, can exacerbate these impacts on Kentucky. But
        the good news is that there is much more that can be done to lessen
        these impacts. Through its many partnerships and applied climatology
        program, the KCC's innovative work is contributing to making the
        Commonwealth safer, its economy more robust, and its infrastructure more
        secure.
      </Typography>
      <Typography paragraph>
        But beyond that, we also want the Kentucky Climate Center to be your
        home for sharing your weather stories, anecdotes, and photos from your
        Kentucky history. Climate records aren't limited to numbers and figures;
        a complete and thorough climate record includes the enduring human
        impacts that those events have on us.
      </Typography>

      <Typography paragraph>
        One personal memento that I'll treasure is my grandfather's personal
        diary of the Louisville flood of 1937. He describes in detail how he and
        many others rescued families from their homes. While the precipitation
        figures from that winter reflect a uniquely rare event, it's the stories
        and photos from those floods that paint a much more holistic
        understanding.
      </Typography>
      <Typography paragraph>
        My first recollection of extreme weather was a heavy snow in January of
        1978. Looking back at the historical record, that single snow event was
        but one of several that winter, comprising one of the snowiest and
        coldest periods in recent Kentucky history. Yet it was the impacts from
        that snow that left its mark - it snowed so much that my kindergarten
        class was canceled the entire month. And it's from these stories that we
        learn to appreciate the challenges of nature, but also how we can better
        prepare ourselves for it going forward.
      </Typography>
      <Typography paragraph>
        Please consider joining us in educational outreach, a research
        collaboration, and the sharing of your weather stories. We look forward
        to serving you and the state of Kentucky!
      </Typography>
      <Typography>Jerry Brotzge, PhD</Typography>
      <Typography>Director, Kentucky Climate Center</Typography>
    </Box>
  );
}
