import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { useState } from "react";

const maxFeedToShow = 10;

export default function NewsFeed() {
  // const { data, isLoading, isError } = useFeed();
  const [feed, setFeed] = useState([]);
  const [feedToShow, setFeedToShow] = useState([]);
  const [currentFeedShown, setCurrentFeedShown] = useState(maxFeedToShow);

  useEffect(() => {
    fetch("/api/getNewsFeed")
      .then((res) => res.json())
      .then((json) => {
        setFeed(json);
        setFeedToShow(json.slice(0, maxFeedToShow));
      });
  }, []);

  return (
    <Box sx={{ py: 1 }}>
      {/* <Typography variant="h4">News Feed</Typography> */}
      <List
      // sx={{ maxHeight: 1500, overflowY: "auto" }}
      >
        {feedToShow.map((i, idx) => (
          <React.Fragment key={i.link}>
            {idx > 0 && <Divider sx={{ bgcolor: "#FEFEFE" }}></Divider>}
            <ListItem>
              <ListItemButton href={i.link}>
                <ListItemIcon>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 60,
                      height: 60,
                      background: "rgb(207, 21, 45)",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FEFEFE",
                        fontWeight: 900,
                        width: 45,
                      }}
                    >
                      {String(moment(i.pubDate).format("MMM DD")).toUpperCase()}
                    </Typography>
                  </Box>
                </ListItemIcon>
                <ListItemText
                  sx={{ mx: 1, color: "#FEFEFE" }}
                  primary={i.title}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="#FEFEFE"
                      >
                        {i.source}
                      </Typography>
                    </React.Fragment>
                  }
                ></ListItemText>
              </ListItemButton>
            </ListItem>
          </React.Fragment>

          // <FeedContainer
          //   publishDate={i.pubDate}
          //   creator={i.source}
          //   key={i.link}
          //   title={i.title}
          //   link={i.link}
          // ></FeedContainer>
        ))}
      </List>

      {feed.length > currentFeedShown && (
        <Button
          onClick={() => {
            let ncfs = currentFeedShown + maxFeedToShow;
            setFeedToShow(feed.slice(0, ncfs));
            setCurrentFeedShown(ncfs);
          }}
          sx={{ color: "#FEFEFE" }}
        >
          Load more
        </Button>
      )}
    </Box>
  );
}
