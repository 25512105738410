import { Box, Button, Typography } from "@mui/material";
import NewsFeed from "../component/NewsFeed";
import Page from "../component/Page";
import WelcomeMessage from "../component/WelcomeMessage";
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import Image from "next/image";
import { DataRequestLink } from "../component/Utils/utils";

const images = [
  {
    src: "https://www.cpc.ncep.noaa.gov/products/expert_assessment/mdohomeweb.png",
    desc: "Kentucky Drought Map",
  },
  {
    src: "https://www.cpc.ncep.noaa.gov/products/predictions/30day/off15_temp.gif",
    desc: "Monthly Temperature Outlook",
  },
  {
    src: "https://www.cpc.ncep.noaa.gov/products/predictions/30day/off15_prcp.gif",
    desc: "Monthly Precipitation Outlook",
  },
];

export default function Home() {
  const [stationsNumber, setStationsNumbers] = useState(0);
  useEffect(() => {
    fetch("/api/getStationsInfo").then(data => data.json()).then((json) => {
      setStationsNumbers(Object.keys(json).length)
    })
  }, []);
  return (
    <Page>
      <Box sx={{ height: 600 }}>
        <Box sx={{ height: 600, width: "100%", position: "absolute", background: "rgba(0,0,0,0.5)" }}>
          <video autoPlay muted preload="auto" loop style={{
            objectFit: "cover", width: "100%", height: "100%", objectPosition: "center bottom"
          }}>
            <source src="./background/vbg.mp4" type="video/mp4" />
            <p>{"Your browser does not support the <video> tag"}</p>
          </video>
        </Box>
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              p: {
                xs: 2,
                sm: 5,
                md: 10,
              },
              display: "flex",
              flexDirection: "column",
              textShadow: "2px 2px 2px rgba(0,0,0,0.5)",
            }}
          >
            <Typography sx={{ color: "#FEFEFE", fontWeight: 900, fontSize: { xs: "2.6rem", sm: "3.2rem", md: "4rem", lg: "5rem" } }}>
              Measuring Weather.
            </Typography>
            <Typography sx={{ color: "#FEFEFE", fontWeight: 900, fontSize: { xs: "2.6rem", sm: "3.2rem", md: "4rem", lg: "5rem" } }}>
              Establishing{" "}
              <span style={{ color: "#cf152d" }}>Climate</span>.
            </Typography>
          </Box>
          <Box sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "left" },
            ml: {
              md: 10,
            },
            flexWrap: "wrap",
          }}>
            <Box sx={{
              background: "#FEFEFE", width: { xs: "80vw", md: 250 }, p: 3,
              boxShadow: "3px 3px 3px rgba(0,0,0,0.35)"
            }}>
              <Typography variant="h5" sx={{ mx: 1 }}>Data Request</Typography>
              <Typography variant="body2" sx={{ m: 1 }}>Need Data? Try our data request tool</Typography>
              <Button target="_blank" href={DataRequestLink} >Request<ArrowForwardIcon /></Button>
            </Box>
            <Box sx={{
              background: "#E1E1E1", width: { xs: "80vw", md: 250 }, p: 3,
              boxShadow: "3px 3px 3px rgba(0,0,0,0.35)"
            }}>
              <Typography variant="h5" sx={{ mx: 1 }}>Inquries</Typography>
              <Typography variant="body2" sx={{ m: 1 }}>Have questions? Don't hesitate to reach us</Typography>
              <Button href="/contact">Contact us<ArrowForwardIcon /></Button>
            </Box>
            <Box sx={{
              background: "#14213d", color: "#FEFEFE", width: { xs: "80vw", md: 250 }, p: 3,
              boxShadow: "3px 3px 3px rgba(0,0,0,0.35)"
            }}>
              <Typography variant="h5" sx={{ mx: 1 }}>Topics</Typography>
              <Typography variant="body2" sx={{ m: 1 }}>Interested in climate? Explore our vast topics</Typography>
              <Button href="/topic" sx={{ color: "#FEFEFE" }}>Explore<ArrowForwardIcon /></Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap-reverse",
        }}
      >
        <Box sx={{ flex: 1, background: "#14213d", p: 2, minWidth: 350, maxWidth: { md: "40vw" } }}>
          <Box sx={{ mx: 1 }}>
            <Typography variant="h4" sx={{ color: "#FEFEFE", }}>News Feed</Typography>
            <NewsFeed></NewsFeed>
          </Box>
        </Box>
        <Box sx={{ flex: 1, minWidth: "60vw" }}>
          <Box sx={{
            px: 3,
            pt: { xs: 20, md: 5 }
            , mb: 2, mt: 3
          }} variant="outlined">
            {/* <Typography variant="h4">Maps</Typography> */}
            <Carousel sx={{ textAlign: "center" }} navButtonsAlwaysVisible>
              {images.map((i, idx) => (
                <React.Fragment key={"index_map_" + idx}>
                  <Box sx={{ position: "relative", aspectRatio: "3300 / 2550" }}>
                    <Image src={`${i.src}?t=${Math.ceil(Date.now() / 60000)}`} alt={i.desc} fill priority></Image>
                  </Box>
                </React.Fragment>
              ))}
            </Carousel>
          </Box>
          <Box sx={{ background: "#e1e1e1", p: 5 }}>
            <WelcomeMessage></WelcomeMessage>
          </Box>
        </Box>
      </Box>
      <Box sx={{ background: "#fefefe" }}>
        <Box sx={{ textAlign: "center", px: { xs: 5, sm: 15 }, py: { xs: 10, md: 15 } }}>
          <Typography variant="h2">Kentucky Climate Center</Typography>
          <Typography variant="h6" sx={{ py: 2 }}>
            The Kentucky Climate Center (KCC) is the state's official climate
            office located at Western Kentucky University. The KCC responsible for:
          </Typography>

          <Box sx={{ textAlign: "center" }}>
            <Box style={{ display: "inline-block", textAlign: "left", maxWidth: 850 }}>
              <Box sx={{ display: "flex" }}>
                <CheckIcon sx={{ mx: 1 }}></CheckIcon>
                <Typography>
                  Collect, Disseminate, and Assess the weather and climate information across the Commonwealth
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckIcon sx={{ mx: 1 }}></CheckIcon>
                <Typography>
                  Lead research, educational, and outreach activities in
                  collaboration with federal, state, and local governments; private
                  sector firms; universities; and non-profit organizations
                </Typography>
              </Box>
            </Box>

          </Box>
          {/* <Box sx={{ py: 2 }}>
            {partners.map((i) => (
              <img
                key={i.name}
                src={i.logo}
                style={{
                  padding: 15,
                  mixBlendMode: "multiply",
                  height: 75,
                  cursor: "pointer",
                }}
                onClick={() => rounter.push(i.url)}
                alt={i.name}
              ></img>
            ))}
          </Box> */}
          <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", my: 8 }}>
            <Box width={300} sx={{ py: 3 }}>
              <Typography variant="h2">{1978}</Typography>
              <Typography variant="overline">the year KCC established</Typography>
            </Box>
            <Box width={300} sx={{ py: 3 }}>
              <Typography variant="h2">{stationsNumber}</Typography>
              <Typography variant="overline">stations established (and counting)</Typography>
            </Box>

            <Box width={300} sx={{ py: 3 }}>
              <Typography variant="h2">1,440</Typography>
              <Typography variant="overline">data points recorded per station daily</Typography>
            </Box>
          </Box>
          <Button href="/about" variant="contained" sx={{ px: 10, py: 2 }}>Learn more about the KCC</Button>
        </Box>

      </Box>
    </Page >
  );
}
